<template>
    <div id="success_alert" class="fullscreen">
        <v-container class="fill-height text-xs-center justify-center">
            <div class="justify-center mt-auto mb-auto">
                <v-alert
                    v-for="message in $store.state.successAlert.messages_success_alert"
                    :type="message.type"
                    class="ml-0 mr-1 w-100"
                    text
                >
                    <template v-slot:prepend>
                        <v-icon></v-icon>
                    </template>

                    <div class="text-center">
                        <div class="pb-2">
                            <v-icon v-if="message.type === 'error'" :color="message.type"> mdi-alert-circle</v-icon>
                            <v-icon v-else-if="message.type === 'success'" :color="message.type">
                                mdi-checkbox-marked-circle
                            </v-icon>
                        </div>

                        <div class="font-18">{{ message.value_top }}</div>
                        <div v-if="message.value_bottom !== ''" class="mt-3 font-18">
                            {{ message.value_bottom }}
                        </div>
                    </div>
                </v-alert>

                <div v-if="$store.state.successAlert.listen" class="text-center mt-10">
                    <p class="font-12 mb-2 text-listen">
                        {{ $t('success_alert.visualize_data_now_switch_device_using_magnet') }}
                    </p>

                    <v-btn block class="validation-button" color="primary" large @click="showListeningMode()">
                        {{ $t('success_alert.switch_listening_mode') }}
                    </v-btn>
                </div>
            </div>

            <router-link
                :to="{ name: 'dashboard' }"
                class="home-link"
                @click.native="$store.commit('successAlert/hideSuccessAlert', 0)"
            >
                {{ $t('success_alert.back_home_page') }}
            </router-link>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'SuccessAlert',
    methods: {
        showListeningMode() {
            if (this.$router.currentRoute.name !== 'listen_mode') {
                this.$router.push('listen_mode')
            }
            this.$store.commit('successAlert/hideSuccessAlertButSaveMessages', 0)
        },
    },
}
</script>

<style>
#success_alert {
    top: 0;
    left: 0;
    z-index: 98;
}

.home-link {
    position: fixed;
    bottom: 20px;
    color: #4c545f !important;
    text-decoration: none;
}

.text-listen {
    font-size: 12px !important;
    color: #4c545f !important;
    line-height: 14px !important;
}
</style>
