<template>
    <div id="order-devices">
        <qr-code-scanner-component v-if="scanner" v-on:cancel="scanner = false" v-on:scanned="getDataScanner" />

        <v-container>
            <Header :text="$t('order_devices.link_device_to_professional')" :title="$t('order_devices.associate')" />

            <div id="desk-selection" class="justify-center">
                <v-select
                    v-model="selected_professional"
                    :disabled="professionals.length === 0"
                    :item-text="(item) => item.distributor_name + ' - ' + item.account_origin"
                    :items="professionals"
                    :label="$t('order_devices.desk_name')"
                    :loading="loading_professional"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-value="id_professional"
                    outlined
                    return-object
                >
                    <template v-slot:prepend-item>
                        <v-list-item>
                            <v-list-item-content class="ma-0 pa-0">
                                <v-text-field
                                    v-model="searchProfessionals"
                                    :placeholder="$t('order_devices.search')"
                                    append-icon="mdi-magnify"
                                    @input="filterDesk"
                                />
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-slot:item="{ item }"> {{ item.distributor_name }} - {{ item.account_origin }}</template>

                    <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-content class="ma-0 pa-0">
                                {{ $t('order_devices.no_data_found') }}
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </div>

            <div v-if="selected_professional !== ''" id="scanner">
                <div v-if="$store.state.last_devices_entered.length > 0" class="device-history pb-2 px-3 mb-4">
                    <p class="text pt-3 mb-1 font-weight-bold history-text">
                        {{ $t('order_devices.latest_device_captured') }}
                    </p>
                    <p class="text history-text">
                        {{ $t('order_devices.duplicate_device_field') }}
                    </p>

                    <v-row class="mt-2 ma-0">
                        <v-col class="pa-0">
                            <v-chip
                                v-for="(last_devices_entered, index) in $store.state.last_devices_entered"
                                class="mr-2 mb-1"
                                close
                                color="grey"
                                outlined
                                small
                                @click="getDataScanner(last_devices_entered)"
                                @click:close="$store.commit('removeLastDeviceEntered', index)"
                            >
                                <span class="text-chips">{{ last_devices_entered }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </div>

                <v-combobox
                    v-model="selected_devices"
                    :label="$t('order_devices.device_number_to_associate')"
                    :loading="loading_scanner"
                    class="device-input"
                    multiple
                    outlined
                    persistent-hint
                    return-object
                    small-chips
                >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                            v-if="item === Object(item) && item.value !== ''"
                            :color="item.color"
                            :input-value="selected"
                            class="mt-2"
                            v-bind="attrs"
                        >
                            <v-avatar left>
                                <v-icon v-if="item.color === 'error'">mdi-alert-circle</v-icon>
                                <v-icon v-else-if="item.color === 'success'">mdi-checkbox-marked-circle</v-icon>
                                <v-icon v-else color="white">mdi-help-circle</v-icon>
                            </v-avatar>

                            <span class="pr-2">
                                {{ item.value }}
                            </span>

                            <v-icon color="white" small @click="parent.selectItem(item)"> $delete</v-icon>
                        </v-chip>
                    </template>

                    <template v-slot:append>
                        <v-icon append color="blue" @click="openScanner()">mdi-qrcode-scan</v-icon>
                    </template>
                </v-combobox>

                <v-select
                    v-if="getValidDevice.length > 0"
                    v-model="selected_contract"
                    :disabled="contracts.length === 0"
                    :items="contracts"
                    :label="$t('order_devices.contract')"
                    :loading="loading_contract"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details
                    item-value="id_contract_device"
                    outlined
                    return-object
                >
                    <template v-slot:item="{ item }"> {{ item.description }} - {{ item.price }} €</template>

                    <template v-slot:selection="{ item }"> {{ item.description }} - {{ item.price }} €</template>
                </v-select>
                <p class="text ml-1">
                    {{ $t('order_devices.12_month_subscription_included') }}
                </p>
            </div>

            <v-btn
                :class="invalid ? '' : 'validation-button'"
                :disabled="invalid"
                block
                color="primary"
                large
                @click="validation_dialog = true"
            >
                {{ $t('global.validate') }}
            </v-btn>

            <v-dialog v-model="validation_dialog" max-width="600">
                <v-card>
                    <v-card-title class="card-title text-uppercase">
                        {{ $t('order_devices.confirm_association') }}
                    </v-card-title>

                    <v-card-text class="mt-4 pb-0">
                        <div class="justify-center text-center">
                            <p class="card-subtitle">
                                {{ $t('order_devices.device_to_associate') }}
                            </p>

                            <div v-for="selected_device in getValidDevice" class="mt-1">
                                <v-chip color="success">
                                    {{ selected_device }}
                                </v-chip>
                            </div>

                            <v-divider class="mt-2 mb-2" />

                            <p class="card-subtitle">
                                {{ $t('order_devices.for_professional') }}
                            </p>
                            <p class="mb-1 font-14">{{ selected_professional.distributor_name }} - {{ selected_professional.city }}</p>

                            <v-divider class="mt-2 mb-2" />

                            <p class="card-subtitle">
                                {{ $t('order_devices.contract') }}
                            </p>
                            <p class="mb-1 font-14">{{ selected_contract.description }}</p>

                            <v-divider class="mt-2 mb-2" />

                            <p v-if="selected_devices.length === 1" class="validation-title">
                                {{ $t('order_devices.confirm_association_level_device') }}
                            </p>
                            <p v-else class="validation-title">
                                {{ $t('order_devices.confirm_association_level_device_length', { devices: selected_devices.length }) }}
                            </p>
                        </div>
                    </v-card-text>

                    <v-container>
                        <v-btn block class="validation-button" color="primary" large @click.prevent="orderDevice()">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-1" large text @click="validation_dialog = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Header from '../../components/Header'
import Loader from '/src/components/Loader'
import SuccessAlert from '../../components/SuccessAlert'
import DeviceRepository from '../../repositories/DeviceRepository'
import ProfessionalRepository from '../../repositories/ProfessionalRepository'
import ContractRepository from '../../repositories/ContractRepository'
import QrCodeScannerComponent from '@/components/QrCodeScannerComponent.vue'

export default {
    name: 'OrderDevices',
    components: {
        QrCodeScannerComponent,
        Header,
        Loader,
        SuccessAlert,
    },
    data() {
        return {
            professionals: [],
            selected_professional: '',
            loading_professional: false,
            //for filter
            professionalsCopy: [],
            searchProfessionals: '',

            selected_devices: [],
            scanner: false,
            loading_scanner: false,

            contracts: [],
            selected_contract: '',
            loading_contract: false,

            validation_dialog: false,
        }
    },
    created() {
        this.getProfessional()
    },
    computed: {
        invalid: function () {
            let devices_valid = false
            this.selected_devices.forEach((selected_device) => {
                if (selected_device.color === 'success') {
                    devices_valid = true
                }
            })
            return !(this.selected_professional !== '' && this.selected_contract !== '' && devices_valid)
        },
        getValidDevice: function () {
            let valid_device = []
            this.selected_devices.forEach((device) => {
                if (device.color === 'success') {
                    valid_device.push(device.value)
                }
            })
            return valid_device
        },
    },
    methods: {
        openScanner() {
            this.scanner = true
            this.hideKeyboard()
        },
        getProfessional() {
            this.loading_professional = true
            ProfessionalRepository.getProfessional()
                .then((success) => {
                    this.professionals = success.data.data
                    this.professionalsCopy = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_professional = false
                })
        },

        getContracts() {
            this.loading_contract = true
            let devices = this.getValidDevice
            let valid_device = ''

            devices.forEach((device, index) => {
                valid_device += device
                if (index !== devices.length - 1) {
                    valid_device += ','
                }
            })

            ContractRepository.getContracts(this.selected_professional.id_professional, valid_device)
                .then((success) => {
                    this.contracts = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_contract = false
                })
        },

        filterDesk() {
            if (!this.searchProfessionals) {
                this.professionals = this.professionalsCopy
            }
            this.professionals = this.professionalsCopy.filter((professional) => {
                return (
                    professional.distributor_name.toLowerCase().indexOf(this.searchProfessionals.toLowerCase()) > -1 ||
                    professional.city.toLowerCase().indexOf(this.searchProfessionals.toLowerCase()) > -1
                )
            })
        },

        getDataScanner(uid) {
            this.copy(uid)

            this.scanner = false
            let already_selected = false
            this.selected_devices.forEach((device) => {
                if (device.value === uid) {
                    already_selected = true
                }
            })
            if (!already_selected) {
                let device = {
                    value: uid,
                    color: 'disabled',
                }
                this.selected_devices.push(device)
                this.checkDeviceIsOrderable(this.selected_devices.length - 1)
            }
        },

        checkDeviceIsOrderable(index) {
            this.loading_scanner = true
            this.$store.commit('addLastDeviceEntered', this.selected_devices[index].value)
            DeviceRepository.checkDeviceToOrder(this.selected_devices[index].value)
                .then((success) => {
                    if (success.data.data.is_orderable) {
                        this.selected_devices[index].color = 'success'
                        this.getContracts()
                    } else {
                        this.selected_devices[index].color = 'error'
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['device_cant_be_added_to_new_order'],
                        })
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                    this.selected_devices[index].color = 'error'
                })
                .finally(() => {
                    this.loading_scanner = false
                })
        },

        addDevice(device) {
            this.selected_devices.pop()
            this.selected_devices.push(device)
            this.checkDeviceIsOrderable(this.selected_devices.length - 1)
        },

        orderDevice() {
            this.validation_dialog = false
            this.$store.commit('loader/showLoader', this.$t('order_devices.association_in_treatment'))
            DeviceRepository.orderDevice(
                this.getValidDevice,
                this.selected_professional.id_professional,
                this.selected_contract.id_contract_device,
            )
                .then(() => {
                    this.$store.commit('successAlert/showSuccessAlert', false)
                    this.$store.commit('successAlert/addMessageToSuccessAlert', {
                        value_top: this.$t('order_devices.association_success'),
                        value_bottom: this.$t('order_devices.can_install_device_professional_premises'),
                        type: 'success',
                    })
                    this.$store.commit('successAlert/hideSuccessAlert', 5000)
                    this.$router.push('dashboard')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.$store.commit('loader/hideLoader')
                })
        },

        hideKeyboard() {
            //this set timeout needed for case when hideKeyborad
            //is called inside of 'onfocus' event handler
            setTimeout(function () {
                //creating temp field
                var field = document.createElement('input')
                field.setAttribute('type', 'text')
                //hiding temp field from peoples eyes
                //-webkit-user-modify is nessesary for Android 4.x
                field.setAttribute(
                    'style',
                    'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;',
                )
                document.body.appendChild(field)

                //adding onfocus event handler for out temp field
                field.onfocus = function () {
                    //this timeout of 200ms is nessasary for Android 2.3.x
                    setTimeout(function () {
                        field.setAttribute('style', 'display:none;')
                        setTimeout(function () {
                            document.body.removeChild(field)
                            document.body.focus()
                        }, 14)
                    }, 200)
                }
                //focusing it
                field.focus()
            }, 50)
        },
    },
    watch: {
        selected_devices(val, prev) {
            if (val.length === 0) {
                this.selected_contract = ''
                this.contracts = []
            }
            if (val.length === prev.length) return
            this.selected_devices = val.map((v) => {
                if (typeof v === 'string') {
                    v = v.replace(/ /g, '')
                    v = {
                        value: v,
                        color: 'disabled',
                    }
                    this.addDevice(v)
                }
                return v
            })
        },
    },
}
</script>

<style>
.validation-title {
    font-weight: bold !important;
    font-size: 18px !important;
    margin-top: 20px !important;
    margin-bottom: 5px !important;
}

.device-history {
    background-color: #d4e1e5;
}

.history-text {
    line-height: 8px !important;
}

.text-chips {
    color: #4c545f !important;
}
</style>
